// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement, forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

import '../style/css';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M8 15A7 7 0 108 1a7 7 0 000 14zM4.5 8.2l.7-.7L7 9.3l3.8-3.8.7.7L7 10.7 4.5 8.2z","fillOpacity":0.9}}]};

const CheckCircleFilledIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'check-circle-filled',
    ref,
    icon: element,
  },
));

CheckCircleFilledIcon.displayName = 'CheckCircleFilledIcon';

export default CheckCircleFilledIcon;
